import React, { useEffect, useState } from 'react';
import { SignUpLayout } from '../../../components/Layout';
import { Link } from 'gatsby';
import okIcon from '../../../assets/img/icons/success.svg';
import queryString from 'query-string';
import has from 'lodash/has';

export default ({ location }) => {
  const [fromMobileApp, setFromMobileApp] = useState(false);
  const [platform, setPlatform] = useState(null);

  useEffect(() => {
    const validParams = queryString.parse(location.search);

    if (has(validParams, 'platform')) {
      setPlatform(validParams.platform);
    }
  }, [location.search]);

  useEffect(() => {
    setFromMobileApp(
      platform && (platform === 'ANDROID' || platform === 'IOS') ? true : false,
    );
  }, [platform]);

  return (
    <SignUpLayout
      siteTitle="Inscrivez votre résidence"
      fromMobileApp={fromMobileApp}
    >
      <section className="container step new-address mt-10 pl-5-md pr-5-md">
        <div className="text-align-center mt-2">
          <img src={okIcon} alt="Alacaza - Inscription" />
        </div>

        <div className="row">
          <div className="col-12">
            <div className="roboto bold font-size-lg font-size-md-sm text-align-center">
              Merci !
            </div>
            <div className="mt-5 font-size-sm-sm text-align-center">
              Votre demande a bien été enregistrée. Nous reviendrons vers vous
              rapidement !
            </div>

            <div className="mt-5 text-align-center">
              <Link to={'/'} className="btn">
                Continuer
              </Link>
            </div>
          </div>
        </div>
      </section>
    </SignUpLayout>
  );
};
